import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Banner from "../components/Banner";
import Paragraph from "../utils/Paragraph";
export default function SystemChange() {
  const data = [
    {
      title:
        "Our primary focus is the construction and real estate sector, as the vast majority of its 62 million workers are migrants. Key activities include:",
      icon: "/images/agreement-icon.png",
      points: [
        "Partnering with real estate companies to facilitate protections for workers in their supply chain, while demonstrating a low-cost, easy-to-implement model.",
        "Partnering with investors to explore incentives and normalize social protection for their investees.",
      ],
    },
    {
      title: "Till date MRC has:",
      icon: "/images/network.svg",
      points: [
        "Formalized partnerships with four state governments",
        "Partnered with 16 large real estate companies and two large real estate investors",
      ],
    },
  ];
  return (
    <Layout>
      <Seo
        title="Systems Change"
        url="https://migrantresilience.org/systems-change"
      />
      <Banner
        src="/images/Banner Image Systems Change Page.jpg"
        position="object-center"
      />
      <div className="w-full flex flex-col justify-center items-start relative z-0 lg:px-28 md:px-12 px-4 overflow-hidden pb-12 pt-6 lg:pt-0">
        <h1 className="text-left lg:mt-4 mb-4 text-darkblue md:text-4xl text-2xl font-bold">
          Systems Change
        </h1>
        <p className="text-darkblue font-bold md:text-base text-sm leading-tight">
          Building on the credibility of our implementation work, MRC also
          supports government and activates industry to provide a clear and
          sustainable pathway for social protection.
        </p>
        <img
          src="/images/dot-pattern.png"
          className="absolute top-0 left-20 w-80 object-contain opacity-80 -z-1"
        ></img>
        <div className="grid lg:grid-cols-2 lg:grid-rows-1 grid-cols-1 auto-rows-auto gap-12 w-full lg:my-8 my-12">
          <IconBox
            icon={"/images/social-security.svg"}
            title="Government infrastructure"
          >
            <ul className="list-none leading-tight gap-4 flex flex-col md:text-base text-sm">
              <li>
                We partner with governments, primarily at the state-level, to
                support the design, development and implementation of
                tech-enabled delivery infrastructure. By creating systems that
                address barriers migrants face, we ensure faster, better and
                cheaper delivery of benefits.
              </li>
            </ul>
          </IconBox>
          <IconBox icon={"/images/worker.svg"} title="Activating industry">
            <ul className="list-none leading-tight gap-4 flex flex-col md:text-base text-sm">
              <li>
                We partner with investors and large employers to
                institutionalize social protection into their business practice.
                The objective is to increase incentives and accountability for
                these actors, which have the potential to enable and fund access
                to social protection for the millions of migrants in their
                supply chains.
              </li>
            </ul>
          </IconBox>
        </div>
      </div>
      <div className="relative mb-20 lg:px-28 md:px-12 px-4">
        <div className="relative w-full h-auto top-0 left-0 flex flex-col justify-start items-start mb-8">
          <div className="grid lg:grid-cols-1 grid-cols-1 auto-rows-auto gap-8 w-full">
            {data?.map((row, key) => (
              <PointBox data={row} key={"goals" + key} />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
}
export const IconBox = ({ icon, title, children }) => {
  return (
    <div className="w-full flex flex-col justify-start items-start gap-4 p-4 rounded-2xl group hover:bg-white bg-[#F1F7FF] shadow-xl border-1 border-transparent hover:border-darkblue transition-all duration-300 ease">
      <div className="lg:h-24 lg:w-24 w-20 h-20 rounded-2xl lg:p-4 p-3 flex flex-col shrink-0 justify-center items-center group-hover:bg-[#F1F7FF] bg-white text-4xl text-darkblue transition-all duration-300 ease">
        <img src={icon} className="w-full object-contain" />
      </div>
      <p className="text-darkblue font-bold lg:text-lg md:text-base text-base leading-tight lg:max-w-[95%] mb-0">
        {title}
      </p>
      <div className="flex flex-col w-full">{children}</div>
    </div>
  );
};
const PointBox = (props) => {
  const { data } = props;
  return (
    <div
      className={`w-full overflow-hidden h-full discover bg-white rounded-3xl flex md:flex-row flex-col md:gap-8 gap-4 justify-start md:items-start items-start lg:p-8 p-4  ${props.className}`}
    >
      <div
        className="md:w-32 md:h-32 h-20 w-20 rounded-3xl md:p-8 p-5 flex flex-col shrink-0 justify-center items-center"
        style={{ backgroundColor: "#F1F7FF" }}
      >
        <img src={data.icon} className="w-full object-contain"></img>
      </div>
      <div className="flex flex-col w-full h-full gap-4">
        <div className="rounded-lg flex px-4 md:py-1 py-2 w-full justify-start items-center bg-[#F1F7FF]">
          <p className="text-black font-semibold md:text-base text-sm leading-none md:max-w-[90%]">
            {data?.title}
          </p>
        </div>

        {data.points && (
          <ul className="w-full list-disc pl-4 flex flex-col gap-2">
            {data.points.map((row, key) => (
              <li
                className="text-[#1D3547] lg:text-sm text-sm font-normal w-full md:leading-tight"
                key={"point" + key}
              >
                {row}
              </li>
            ))}
          </ul>
        )}

        {/* <p className="text-gray lg:mt-4 text-sm lg:text-center">{props.description}</p> */}
      </div>
    </div>
  );
};
